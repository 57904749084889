import { height } from "@mui/system";

export const userColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "username",
    headerName: "Customer Name",
    width: 150,
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },
  {
    field: "mobileNumber",
    headerName: "Phone",
    width: 150,
  }
];
