import "./dtatable.css";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "../../dataTableSource";
import { Link } from "react-router-dom";
import { useContext } from "react";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import { CustomerContext } from "../../context/customrContext";

import loadingGIf from "../../images/loading.gif";
const Datatable = () => {
  const { customerData, setCustomerData } = useContext(CustomerContext);

  let baseUrl;
  if (process.env.NODE_ENV === "development") {
    baseUrl = process.env.REACT_APP_BACKEND_LOCALAPI;
  } else {
    baseUrl = process.env.REACT_APP_BACKEND_LIVEAPI;
  }

 
  return (
    <div className="datatable font-serif text-sm">
      <div className="datatableTitle">
        <h2 className="text-xl    tracking-wider leading-10 font-extraligh text-main">
          Customers List
        </h2>
        <Link to="/dashboard/customers/new" className="link">
          <AddCircleSharpIcon className="AddCircleSize" />
        </Link>
      </div>

      {!customerData || customerData.length === 0 ? (
        <div className="flex justify-center items-center">
          <img className="w-32 h-32" src={loadingGIf} alt="" />
        </div>
      ) : (
        <DataGrid
          rowHeight={100}
          className="datagrid"
          rows={customerData.map((customer, index) => ({
            id: index + 1,
            ...customer,
          }))}
          columns={userColumns}
          pageSize={9}
          rowsPerPageOptions={[100]}
          checkboxSelection

        />
      )}
    </div>
  );
};

export default Datatable;
